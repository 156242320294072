import "core-js/modules/es.array.push.js";
import Loading from './loading.vue';
export default {
  components: {
    Loading
  },
  data() {
    return {
      kpje: '',
      phone: '',
      showMain: true,
      showFail: false,
      showLoading: false,
      titlecss2: "innertext floatleft systemfont lefttitlest",
      titlecss1: "innertext1 floatleft systemfont lefttitle",
      titlecss: "innertext floatleft systemfont lefttitle",
      valuecss: "innertext systemfont righttitle wordbreak",
      closeyes: 'closebtnc btnyes systemfont',
      closecancel: 'closebtnc btncancel systemfont',
      closeyesy: 'closebtny btnyes systemfont',
      closecancely: 'closebtny btncancel systemfont',
      tempwidth: document.documentElement.clientWidth,
      tempheight: document.documentElement.clientHeight - 68 + 'px',
      timer: null
    };
  },
  watch: {},
  mounted() {
    this.kpje = this.$store.state.fapiaoAmount;
    this.phone = this.$store.state.kpinform.phone;
  },
  methods: {
    onClickBack() {
      this.showMain = false;
      this.showLoading = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        // this.$store.commit('setKpinform', {"buyerType": 0});
        // this.$store.commit('setReceive', {"sendType": 0});
        this.$router.push('fpkj');
      }, 500);
    },
    onClickSubmit() {
      this.showMain = false;
      this.showLoading = true;
      this.axios.post('cdapply/GetInvoice/', {
        "orderBillNos": this.$store.state.orderBillNos,
        "invoiceRequestId": this.$store.state.invoiceRequestId,
        "fapiaoAmount": this.$store.state.fapiaoAmount,
        "kptqcode": this.$store.state.kptqcode,
        "orderList": this.$store.state.orders,
        "isSHDR": this.$store.state.isSHDR,
        "invoiceType": this.$store.state.invoiceRequestInvoiceType,
        "sendInform": this.$store.state.receive,
        "kpinform": this.$store.state.kpinform
      }).then(response => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          var resdata = response.data;
          if (resdata.code == 0) {
            this.$store.commit('setInvoiceRequestAmount', resdata.data['InvoiceAmount']);
            this.$store.commit('setInvoiceRequestDatetime', resdata.data['InvoiceDatetime']);
            this.$store.commit('setInvoiceRequestInvoiceType', resdata.data['InvoiceType']);
            this.$router.push('dealing');
          } else {
            this.showMain = true;
            this.showLoading = false;
            this.showFail = true;
          }
        }, 500);
      }).catch(response => {
        this.$router.push('systemerr');
      });
    }
  }
};